<template>
  <form-field-text
    v-model="sCode"
    :disabled="disabled"
    :loading="loading"
    :rules="sRule"
    flat
    full-width
    label="currency.rya.code"
    rounded
    solo
  >
    <template #append>
      <v-btn
        :disabled="btnDisabled"
        :loading="!!sCode && loading"
        color="primary"
        depressed
        icon
        outlined
        x-small
        @click="onSave"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type {
  CurrencyCompanyPivot,
  CurrencyRate,
} from "@planetadeleste/vue-mc-gw";
import type { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { Currency } from "@planetadeleste/vue-mc-shopaholic";
import { filter, find, isEmpty, isNil, map, set } from "lodash";
import useCompanyHelper from "@/composables/company";
import { AppModule } from "@/store/app";
import { ConfigModule } from "@/store/config";

@Component
export default class CompanyCurrencyRyaCode extends Vue {
  @Prop({ type: Object, required: true }) readonly value!: CurrencyData;
  @Prop({ type: Number, required: true }) readonly companyId!: number;

  obCurrency: Currency | null | undefined = null;
  obCurrencyRate: CurrencyRate | null | undefined = null;
  arCodeList: string[] = [];
  sOriginalCode: string | null = null;
  loading = false;

  get sCode() {
    return this.obCurrency ? this.obCurrency.get("pivot.coderya", null) : null;
  }

  set sCode(sValue: string) {
    if (!this.obCurrency) {
      return;
    }

    const obPivot = this.obCurrency.get(
      "pivot",
      {}
    ) as Partial<CurrencyCompanyPivot>;
    set(obPivot, "coderya", sValue);
    this.obCurrency.set("pivot", obPivot);
  }

  get disabled(): boolean {
    return this.loading || !this.obCurrency?.get("editable", false);
  }

  get isDirty(): boolean {
    return this.sCode !== this.sOriginalCode;
  }

  get btnDisabled(): boolean {
    return (
      !this.sCode || (!this.isDirty && this.arCodeList.includes(this.sCode))
    );
  }

  get sRule() {
    if (!this.arCodeList.length || this.btnDisabled) {
      return "";
    }

    return `excluded:${this.arCodeList.join(",")}`;
  }

  async onSave() {
    if (!this.obCurrency?.id || !this.companyId) {
      return;
    }

    this.loading = true;
    const obCompanyHelper = useCompanyHelper(this.companyId);
    const obPivotData = this.obCurrency.get("pivot") as
      | CurrencyCompanyPivot
      | undefined;
    const obData: CurrencyCompanyPivot =
      isEmpty(obPivotData) || isNil(obPivotData)
        ? {
            currency_id: this.obCurrency.id,
            company_id: this.companyId,
            active: true,
          }
        : obPivotData;
    await obCompanyHelper.addUpdateCurrency(obData as CurrencyCompanyPivot);

    if (this.companyId === AppModule.company.id) {
      await ConfigModule.loadCompanyCurrencies(true);
    }

    this.loading = false;
    await this.load();
  }

  async load() {
    if (this.companyId === AppModule.companyId) {
      const arCompanyCurrencies = ConfigModule.companyCurrencies;
      const obModel = find(arCompanyCurrencies, { id: this.value.id });
      this.arCodeList = map(
        filter(arCompanyCurrencies, (obItem) => !!obItem.get("pivot.coderya")),
        (obItem) => obItem.get("pivot.coderya")
      );

      if (obModel) {
        this.obCurrency = obModel;
      }
    } else {
      this.loading = true;
      const obCompanyHelper = useCompanyHelper(this.companyId);
      const arCurrencies = await obCompanyHelper.loadCurrencies();
      const obCurrencyData = find(arCurrencies, { id: this.value.id });
      this.arCodeList = map(arCurrencies, "pivot.coderya");

      if (obCurrencyData) {
        this.obCurrency = new Currency(obCurrencyData as CurrencyData);
      }
    }

    this.setOriginalCode();

    /*const obCollection = new CurrencyRates();
    // @ts-ignore
    await obCollection.filterBy({ companyGrouped: this.companyId }).fetch();

    this.obCurrencyRate = obCollection.find({ currency_id: this.value.id });
    this.arCodeList = obCollection
      .filter(
        (obModel: CurrencyRate) =>
          !!get(obModel, "coderya") &&
          !!this.obCurrencyRate &&
          this.obCurrencyRate.id !== obModel.id
      )
      .map("coderya");*/
    this.loading = false;
  }

  setOriginalCode() {
    if (!this.obCurrency) {
      return;
    }

    this.sOriginalCode = this.obCurrency.get("pivot.coderya");
  }

  mounted() {
    this.load();
  }
}
</script>
